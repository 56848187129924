import React, { FC } from 'react';
import HeadroomBase from 'react-headroom';
import Scrollspy from 'react-scrollspy';
import styled, { useTheme } from 'styled-components';

import { Link } from '~src/components/link';
import { LogoSVG } from '~src/components/logo-svg';
import { useMedia } from '~src/hooks';

import { SideMenu } from './side-menu';

const Headroom = styled(HeadroomBase)`
  /* Menu Global Styling and Headroom Effects */

  .headroom--scrolled,
  .headroom--unpinned {
    nav {
      ${p => p.theme.minWidth.phone`
        height: 4em;
      `}

      background-color: ${p => p.theme.colors.white};

      .navItems {
        a,
        button {
          color: ${p => p.theme.colors.dark};

          &:hover,
          &.is-current {
            color: ${p => p.theme.colors.red};
          }
        }

        a.is-current::after {
          width: 100%;
        }
      }

      .hamburger span {
        background-color: ${p => p.theme.colors.dark};
      }

      .brand {
        font-size: 1.2em;

        > svg {
          fill: ${p => p.theme.colors.dark};
        }
      }
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${p => p.theme.zIndexes.nav};
  height: ${p => p.theme.headerHeight};
  background-color: transparent;
  transition: all 0.2s ease-in-out;
`;

const NavInner = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 0 4%;
`;

const Brand = styled(Link)`
  height: 100%;
  width: 10em;
  display: flex;
  align-items: center;
  font-size: 1.5em;

  > svg {
    height: 1.25em;
    width: 10em;
    fill: ${p => p.theme.colors.white};
  }
`;

const Anchors = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  ${p => p.theme.maxWidth.phone`
    display: none;
  `}
`;

const AnchorLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1em;
  font-weight: 400;
  text-transform: capitalize;
  width: fit-content;
  height: 100%;
  color: ${p => p.theme.colors.white};
  transition: color 0.2s ease-in-out;
  margin-right: 2em;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    color: ${p => p.theme.colors.gray};
  }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    width: 0;
    height: 3px;
    background: #f9004d;
    transition: 0.3s;
    bottom: 0;
  }
`;

export const anchorData = [
  {
    id: `#hero`,
    label: `home`,
  },
  {
    id: `#services`,
    label: `Services`,
  },
  {
    id: `#projects`,
    label: `Portfolio`,
  },
  {
    id: `#about`,
    label: `We are`,
  },
  {
    id: `#team`,
    label: `Task force`,
  },
  {
    id: `#contact`,
    label: `Contact`,
  },
];

export const Header: FC = () => {
  const { breakpoints } = useTheme();
  const phone = useMedia(`(max-width: ${breakpoints.phone}px)`);

  return (
    <Headroom disableInlineStyles>
      <Nav>
        <NavInner>
          <Brand className="brand" to="/">
            <LogoSVG />
          </Brand>

          {!phone ? (
            <Scrollspy
              componentTag={Anchors}
              className="navItems"
              items={[
                'hero',
                'services',
                'projects',
                'about',
                'team',
                'contact',
              ]}
              offset={-150}
              currentClassName="is-current"
            >
              {anchorData.map(el => (
                <AnchorLink key={el.id} to={el.id}>
                  {el.label}
                </AnchorLink>
              ))}
            </Scrollspy>
          ) : (
            <SideMenu />
          )}
        </NavInner>
      </Nav>
    </Headroom>
  );
};
