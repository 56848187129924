import React, { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

import { __DEV__, ButtonEvent } from '~src/utils';

const Button = styled.button`
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 3em;
  height: 3em;
  font-size: 1em;
  border-radius: 0.375em;
  padding: 0.4em;
  transition: all 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }

  &:active {
    background: rgba(0, 0, 0, 0.08);
  }

  /* &:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
  } */

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

const CloseIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bar = styled.span`
  position: relative;
  width: 2.5em;
  height: 2px;
  background: transparent;
  transition: all 0.2s ease;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    left: 0;
    top: 0;
    transform: rotate(45deg);
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    left: 0;
    bottom: 0;
    transform: rotate(-45deg);
  }
`;

export type CloseButtonProps = {
  /**
   * If `true`, the close button will be disabled.
   */
  isDisabled?: boolean;
  className?: string;
  onClick?: ButtonEvent;
  children?: ReactNode;
};

export const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  (props, ref) => {
    const { children, isDisabled, className, onClick } = props;

    return (
      <Button
        type="button"
        aria-label="Close"
        ref={ref}
        disabled={isDisabled}
        className={className}
        onClick={onClick}
      >
        {children || (
          <CloseIcon>
            <Bar />
          </CloseIcon>
        )}
      </Button>
    );
  }
);

if (__DEV__) {
  CloseButton.displayName = 'CloseButton';
}
