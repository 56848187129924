import React, { FC, MouseEvent } from 'react';
import styled from 'styled-components';

const Bar = styled.span`
  position: relative;
  width: 2.5em;
  height: 2px;
  background: #fff;
  transition: all 0.2s ease;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    left: 0;
    top: -10px;
    ${p => p.theme.maxWidth.phone`
      top: -8px;
    `}
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    left: 0;
    bottom: -10px;
    ${p => p.theme.maxWidth.phone`
      bottom: -8px;
    `}
  }
`;

const Burger = styled.button`
  width: 2.5em;
  height: 1.625em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${p => p.theme.zIndexes.hamburger};

  &:hover {
    ${Bar} {
      opacity: 0.6;
    }
  }
`;

type Props = {
  className?: string;
  onClick: (event: MouseEvent) => void;
};

export const Hamburger: FC<Props> = ({ className, onClick }) => (
  <Burger aria-label="Toggle menu" className={className} onClick={onClick}>
    <Bar />
  </Burger>
);
