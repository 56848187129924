import {
  css,
  CSSObject,
  FlattenSimpleInterpolation,
  SimpleInterpolation,
} from 'styled-components';

type ObjectMap<T> = { [key: string]: T };

export const breakpoints: ObjectMap<number> = {
  small: 361, // 22.5em
  phone: 576, // 36em
  tablet: 769, // 48em
  tabletLandscape: 1025, // 64em
  desktop: 992, // 62em
  wide: 1200, // 75em
  large: 1400, // 87.5em
  xLarge: 1800, // 112.5em
  fullHD: 1920, // 120em
};

//
// ─── MEDIA QUERIES ──────────────────────────────────────────────────────────────
//

type Interpolation = {
  [key: string]: (
    first: CSSObject | TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
  ) => FlattenSimpleInterpolation;
};

export const minWidth = Object.keys(breakpoints).reduce<Interpolation>(
  (acc, label) => {
    acc[label] = (...args) => css`
      @media only screen and (min-width: ${breakpoints[label] / 16}em) {
        ${css(...args)};
      }
    `;
    return acc;
  },
  {}
);

export const maxWidth = Object.keys(breakpoints).reduce<Interpolation>(
  (acc, label) => {
    acc[label] = (...args) => css`
      @media only screen and (max-width: ${(breakpoints[label] - 0.02) /
        16}em) {
        ${css(...args)};
      }
    `;
    return acc;
  },
  {}
);

// ────────────────────────────────────────────────────────────────────────────────
