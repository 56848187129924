import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { theme } from '~src/styles';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};
