/* eslint-disable no-nested-ternary */
import { Link as GatsbyLink } from 'gatsby';
import React, { FC, memo, ReactNode } from 'react';

type Props = {
  to: string;
  title?: string;
  activeClassName?: string;
  partiallyActive?: boolean;
  state?: Record<string, any>;
  children: ReactNode;
  className?: string;
};

/**
 * NOTE:
 * This is a useful utility component as it means that when creating
 * an internal or external link, you don't have to switch between using
 * Gatsby's `Link` component and the regular `a` element. This component
 * detects whether or not the link passed to it is an internal or external
 * reference, and renders the correct markup for that link type.
 */

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export const Link: FC<Props> = memo(
  ({
    children,
    to,
    title = '',
    activeClassName = '',
    partiallyActive = false,
    state,
    className = '',
  }) => {
    // Use Gatsby Link for internal links, and <a> for others
    if (
      to.startsWith('https://') ||
      to.startsWith('http://') ||
      to.startsWith('tel:') ||
      to.startsWith('mailto:')
    ) {
      return (
        <a
          href={to}
          title={title}
          rel="noreferrer noopener"
          target={
            to.startsWith('tel:') || to.startsWith('mailto:')
              ? undefined
              : '_blank'
          }
          className={className}
        >
          {children}
        </a>
      );
    }

    const slug = to.startsWith('#') ? `/${to}` : to === '/' ? '/' : `/${to}/`;

    return (
      <GatsbyLink
        to={slug}
        title={title}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        state={state}
        className={className}
      >
        {children}
      </GatsbyLink>
    );
  }
);
