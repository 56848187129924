import { graphql, useStaticQuery } from 'gatsby';

type SiteMetadataReturn = {
  siteTitle: string;
  siteTitleAlt: string;
  siteTitleTemplate: string;
  siteUrl: string;
  siteFeaturedImage: string;
  siteFeaturedImageAlt: string;
  siteDescription: string;
  fbAppID: string;
};

export function useSiteMetadata(): SiteMetadataReturn {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteTitleAlt
          siteTitleTemplate
          siteUrl
          siteFeaturedImage
          siteFeaturedImageAlt
          siteDescription
          fbAppID
        }
      }
    }
  `);

  return data.site.siteMetadata;
}
