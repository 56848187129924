import React, { FC, useState } from 'react';
import Scrollspy from 'react-scrollspy';
import styled from 'styled-components';

import { Link } from '~src/components/link';

import { CloseButton } from './close-button';
import { Hamburger } from './hamburger';

const Wrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transform: ${p => (p.isOpen ? `translateX(0)` : `translateX(100%)`)};
  z-index: ${p => p.theme.zIndexes.popover};
  height: 100vh;
  width: 90vw;
  background-color: ${p => p.theme.colors.white};
  box-shadow: ${p => p.theme.boxShadows.card};
  transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
  padding: 6em 3em;
`;

const NavInner = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  height: auto;
  margin-bottom: auto;
`;

const Close = styled(CloseButton)`
  position: absolute;
  right: 2em;
  top: 2em;
`;

const Anchors = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const AnchorLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 2em;
  font-weight: 400;
  text-transform: capitalize;
  width: 100%;
  height: 3em;
  color: ${p => p.theme.colors.dark};
  transition: color 0.2s ease-in-out;
  margin-right: 2em;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    width: 0;
    height: 3px;
    background: #f9004d;
    transition: 0.3s;
    bottom: 0;
  }

  &.is-current {
    color: ${p => p.theme.colors.red};
  }

  &.is-current::after {
    width: 100%;
  }
`;

export const anchorData = {
  home: {
    id: `#hero`,
    label: `home`,
  },
  services: {
    id: `#services`,
    label: `Services`,
  },
  about: {
    id: `#about`,
    label: `We are`,
  },
  portfolio: {
    id: `#projects`,
    label: `Porfolio`,
  },
  team: {
    id: `#team`,
    label: `Task force`,
  },
};

export const SideMenu: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Wrapper isOpen={isOpen}>
        <Close onClick={() => setIsOpen(false)} />
        <NavInner onClick={() => setIsOpen(false)}>
          <Scrollspy
            componentTag={Anchors}
            className="navItemsMenu"
            items={['hero', 'services', 'projects', 'about', 'team']}
            offset={-150}
            currentClassName="is-current"
          >
            <AnchorLink to={anchorData.home.id}>
              {anchorData.home.label}
            </AnchorLink>
            <AnchorLink to={anchorData.services.id}>
              {anchorData.services.label}
            </AnchorLink>
            <AnchorLink to={anchorData.portfolio.id}>
              {anchorData.portfolio.label}
            </AnchorLink>
            <AnchorLink to={anchorData.about.id}>
              {anchorData.about.label}
            </AnchorLink>
            <AnchorLink to={anchorData.team.id}>
              {anchorData.team.label}
            </AnchorLink>
          </Scrollspy>
        </NavInner>
      </Wrapper>
      <Hamburger onClick={() => setIsOpen(true)} className="hamburger" />
    </>
  );
};
