export const boxShadows = {
  card: `0 0.8125em 1.6875em -0.3125em hsla(240, 30.1%, 28%, 0.25),
         0 0.5em 1em -0.5em hsla(0, 0%, 0%, 0.3),
         0 -0.375em 1em -0.375em hsla(0, 0%, 0%, 0.03)`,
  cardLow: `rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, 
            rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px`,
  imgSection: `0 0.01875em 0.0875em rgba(0, 0, 0, 0.056),
               0 0.04375em 0.20625em rgba(0, 0, 0, 0.081),
               0 0.08125em 0.39375em rgba(0, 0, 0, 0.1),
               0 0.1375em 0.7em rgba(0, 0, 0, 0.119),
               0 0.2625em 1.30625em rgba(0, 0, 0, 0.144),
               0 0.625em 3.125em rgba(0, 0, 0, 0.2)`,
  lightBlueLine: `rgba(82, 131, 155, 0.4) 0 0 1px 1px`,
  light: `0 1px 1px 0 rgba(0, 0, 0, 0.1), 
          0 2px 2px 0 rgba(0, 0, 0, 0.1),
          0 4px 4px 0 rgba(0, 0, 0, 0.1), 
          0 8px 8px 0 rgba(0, 0, 0, 0.1),
          0 16px 16px 0 rgba(0, 0, 0, 0.1)`,
};

export const textShadows = {
  light: `0 1px 1px rgba(0, 0, 0, 0.1), 
          0 2px 2px rgba(0, 0, 0, 0.1),
          0 4px 4px rgba(0, 0, 0, 0.1),
          0 8px 8px rgba(0, 0, 0, 0.1),
          0 16px 16px rgba(0, 0, 0, 0.1)`,
  medium: `0 1px 1px rgba(0, 0, 0, 0.2), 
           0 2px 2px rgba(0, 0, 0, 0.2),
           0 4px 4px rgba(0, 0, 0, 0.2), 
           0 8px 8px rgba(0, 0, 0, 0.2),
           0 16px 16px rgba(0, 0, 0, 0.2)`,
};
