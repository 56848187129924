import React, { FC } from 'react';

import { Header } from '~src/components/header';
import { GlobalStyle } from '~src/styles';

const AppLayout: FC = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
    </>
  );
};

export default AppLayout;
