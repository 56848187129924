import { KeyboardEvent } from 'react';

import {
  BasicTarget,
  Booleanish,
  EventKeys,
  EventMap,
  TargetElement,
} from './types';

let win: Window | undefined;

/**
 * Note: Accessing "window" in IE11 is somewhat expensive, and calling "typeof window"
 * hits a memory leak, whereas aliasing it and calling "typeof win" does not.
 * Caching the window value at the file scope lets us minimize the impact.
 *
 * @see IE11 Memory Leak Issue https://github.com/microsoft/fluentui/pull/9010#issuecomment-490768427
 */
try {
  win = window;
} catch (e) {
  /* no-op */
}

/**
 * Helper to get the window object. The helper will make sure to use a cached variable
 * of "window", to avoid overhead and memory leaks in IE11.
 */
export function getWindow(node?: HTMLElement | null): Window | undefined {
  return node?.ownerDocument?.defaultView ?? win;
}

/**
 * Check if we can use the DOM. Useful for SSR purposes
 */
function checkIsBrowser() {
  const win = getWindow();
  return Boolean(
    typeof win !== 'undefined' && win.document && win.document.createElement
  );
}

export const isBrowser = checkIsBrowser();

/**
 * Get the normalized event key across all browsers
 * @param event keyboard event
 */
export function normalizeEventKey(event: KeyboardEvent): EventKeys {
  const { key, keyCode } = event;

  const isArrowKey =
    keyCode >= 37 && keyCode <= 40 && key.indexOf('Arrow') !== 0;

  const eventKey = isArrowKey ? `Arrow${key}` : key;

  return eventKey as EventKeys;
}

export function dataAttr(condition: boolean | undefined): Booleanish {
  return (condition ? '' : undefined) as Booleanish;
}

export function ariaAttr(condition: boolean | undefined): true | undefined {
  return condition ? true : undefined;
}

export function getDocument(node?: HTMLElement | null): Document | null {
  return (node?.ownerDocument || isBrowser
    ? document
    : null) as Document | null;
}

export function cx(...classNames: any[]): string {
  return classNames.filter(Boolean).join(' ');
}

export function getActiveElement(node?: HTMLElement): HTMLElement {
  const doc = getDocument(node);
  return doc?.activeElement as HTMLElement;
}

export function contains(parent: HTMLElement, child: HTMLElement): boolean {
  return parent === child || parent.contains(child);
}

export function getTargetElement(
  target?: BasicTarget<TargetElement>,
  defaultElement?: TargetElement
): TargetElement | undefined | null {
  if (!target) {
    return defaultElement;
  }

  let targetElement: TargetElement | undefined | null;

  if (typeof target === 'function') {
    targetElement = target();
  } else if ('current' in target) {
    targetElement = target.current;
  } else {
    targetElement = target;
  }

  return targetElement;
}

export function managedEventListener<
  T extends EventTarget,
  K extends keyof EventMap<T> & string
>(
  target: T,
  type: K,
  callback: (event: EventMap<T>[K]) => void,
  options?: AddEventListenerOptions
): () => void {
  target.addEventListener(type, callback as EventListener, options);
  return (): void => {
    target.removeEventListener(type, callback as EventListener, options);
  };
}

export function managedInterval(
  callback: () => void,
  delayMs: number
): () => void {
  const id = setInterval(callback, delayMs);
  return (): void => {
    clearInterval(id);
  };
}

export { win };
