import { css } from 'styled-components';

const SCROLLBAR_WIDTH = 6;

export const customScrollbar = css`
  /* Chrome */
  &::-webkit-scrollbar {
    width: ${SCROLLBAR_WIDTH}px;
    height: ${SCROLLBAR_WIDTH}px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${p => p.theme.colors.red};
  }
  &::-webkit-scrollbar-track {
    background: ${p => p.theme.colors.dark};
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.colors.gray};
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${p => p.theme.colors.red} ${p => p.theme.colors.dark};
`;

export const buttonMixin = css`
  font-size: 1em;
  font-weight: black;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  padding: 1.2em;
  transition: all 0.2s ease-in-out;
  color: ${p => p.theme.colors.white};
  background-color: ${p => p.theme.colors.dark};

  &:hover {
    background-color: ${p => p.theme.colors.gray};
  }
`;
