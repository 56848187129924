import { colors } from './colors';
import { fonts } from './fonts';
import { breakpoints, maxWidth, minWidth } from './responsive';
import { boxShadows, textShadows } from './shadows';
import { zIndexes } from './zIndexes';

export const theme = {
  fonts,
  colors,
  zIndexes,
  boxShadows,
  textShadows,
  minWidth,
  maxWidth,
  breakpoints,
  headerHeight: `5.5em`, // 88px
  headerPhoneHeight: `5em`,
  containerWidth: `90em`, // 1440px
};
